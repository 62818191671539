import React, { useEffect } from "react";
import "../styles/page.scss";
import {
  deleteAStore,
  getStores,
  resetState,
} from "../features/stores/storeSlice";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Stores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getStoresState = useSelector((state) => state.store.stores);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    dispatch(resetState());
    dispatch(getStores());
  }, []);

  const deleteStore = (id) => {
    dispatch(deleteAStore(id));
    setTimeout(() => {
      dispatch(getStores());
    }, 100);
  };

  return (
    <div className="page">
      <h4> Trgovine</h4>
      <p>
        {" "}
        Ovdje mžete pogledati sve trgovine koje su dodane u bazu podataka.{" "}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>Naziv</th>
            <th>Centar u kojem se nalazi</th>
            <th>Promocije koje se nalaze u trgovini</th>
            <th>Uredi trgovinu</th>
            <th>Izbriši trgovinu</th>
          </tr>
        </thead>
        <tbody>
          {getStoresState?.map((store, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{store.title}</td>
              <td>
                {" "}
                {store?.location?.map((location, index) => (
                  <p key={index}> Park & Shop {" " + location}</p>
                ))}
              </td>
              <td>
                {store.promotions.map((promotion, index) => (
                  <p key={index}>{promotion.title}</p>
                ))}
              </td>
              <td>
                <Link className="button" to={`/admin/add-store/${store._id}`}>
                  Uredi
                </Link>
              </td>
              <td>
                <button
                  className="button"
                  onClick={() => deleteStore(store._id)}
                >
                  izbriši
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Stores;
