import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllMall = async () => {
  const response = await axios.get(`${base_url}mall/`);

  return response.data;
};
const createAMall = async (data) => {
  const response = await axios.post(`${base_url}mall/`, data, config);

  return response.data;
};
const updateMall = async (data) => {
  const response = await axios.put(
    `${base_url}mall/${data.location}`,
    data.mallData,
    config
  );

  return response.data;
};
const getMall = async (location) => {
  const response = await axios.get(`${base_url}mall/${location}`, config);

  return response.data;
};

const deleteAMall = async (location) => {
  const response = await axios.delete(`${base_url}mall/${location}`, config);

  return response.data;
};

const mallService = {
  getAllMall,
  createAMall,
  updateMall,
  getMall,
  deleteAMall,
};

export default mallService;
