import React from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../features/users/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useEffect } from "react";
import "../styles/login.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Unesite ispravnu email adresu")
    .required("Niste upisali email adresu"),
  password: Yup.string().required("Niste upisali lozinku"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (isSuccess && localStorage.getItem("user")) {
      navigate("/admin");
    } else {
      navigate("");
    }
  }, [user, isError, isSuccess, isLoading]);

  return (
    <div className="page-login">
      <img
        src="https://parkandshop.hr/images/logo_parkandshop.png"
        alt="logo"
        className="logo"
      />
      <div className="container">
        <h3>Prijavite se</h3>
        <p>
          Unesite email adresu i lozinku kako bi pristupili admin aplikaciji.
        </p>

        <form onSubmit={formik.handleSubmit} className="form">
          <div className="form-group">
            <label htmlFor="email">Email adresa</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Unesite email"
              onChange={formik.handleChange("email")}
              value={formik.values.email}
              onBlur={formik.handleBlur("email")}
            />
            <div className="error">
              {formik.touched.email && formik.errors.email}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Lozinka</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Unesite loziniku"
              onChange={formik.handleChange("password")}
              value={formik.values.password}
              onBlur={formik.handleBlur("password")}
            />
            <div className="error">
              {formik.touched.password && formik.errors.password}
            </div>
          </div>

          <button
            type="submit"
            className="btn"
            disabled={isLoading}
            onClick={formik.handleSubmit}
          >
            PRIJAVI SE
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
