import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import storeService from "./storesService";

export const getStores = createAsyncThunk(
  "stores/get-stores",
  async (thunkAPI) => {
    try {
      return await storeService.getAllStores();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createNewStore = createAsyncThunk(
  "stores/create-store",
  async (data, thunkAPI) => {
    try {
      return await storeService.createAStore(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAStore = createAsyncThunk(
  "stores/get-store",
  async (id, thunkAPI) => {
    try {
      return await storeService.getStore(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAStore = createAsyncThunk(
  "stores/update-store",
  async (data, thunkAPI) => {
    try {
      return await storeService.updateStore(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAStore = createAsyncThunk(
  "stores/delete-store",
  async (id, thunkAPI) => {
    try {
      return await storeService.deleteStore(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  stores: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStores.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.stores = action.payload;
      })
      .addCase(getStores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createNewStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.newStore = action.payload;
      })
      .addCase(createNewStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.store = action.payload;
      })
      .addCase(getAStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedStore = action.payload;
      })
      .addCase(updateAStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedStore = action.payload;
      })
      .addCase(deleteAStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});
export default storeSlice.reducer;
