import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createNewPromotion,
  updateAPromotion,
  getAPromotion,
  resetState,
} from "../features/promotions/promotionsSlice";
import "../styles/page.scss";
import Dropzone from "react-dropzone";
import { BsTrash } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { format, set } from "date-fns";
import { getMall } from "../features/malls/mallSlice";
import { Select } from "antd";
import { uploadServer } from "../utils/baseUrl";

let schema = yup.object().shape({
  title: yup.string().required("Category Name is Required"),
  description: yup.string().required("Description is Required"),
  dateStart: yup.string().required("Date Start is Required"),
  dateEnd: yup.string(),
  content: yup.string().required("Content is Required"),
});
const AddPromotion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const promotionId = location.pathname.split("/")[3];
  const [images, setImages] = useState([]);
  const [locationVariable, setLocationVariable] = useState([]);

  const {
    isLoading,
    isError,
    isSuccess,
    createdPromotion,
    updatedPromotion,
    deletedPromotion,
    promotion,
  } = useSelector((state) => state.promotions);
  const imgState = useSelector((state) => state.upload.images.imageUrls);
  const malls = useSelector((state) => state.mall.allMalls);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    setImages([]);
    dispatch(getMall());
  }, []);

  useEffect(() => {
    if (promotionId !== undefined) {
      dispatch(getAPromotion(promotionId));
    } else {
      dispatch(resetState());
    }
  }, [promotionId]);

  useEffect(() => {
    if (imgState?.length > 0) {
      imgState?.map((image) => {
        if (images?.length > 0) {
          setImages((prev) => [...prev, image]);
        } else {
          setImages(imgState);
        }
      });
    }
  }, [imgState]);

  useEffect(() => {
    formik.setFieldValue("images", images);
  }, [images]);

  useEffect(() => {
    setImages(promotion?.images);
  }, [promotion?.images]);

  useEffect(() => {
    if (promotionId !== undefined) {
      dispatch(getAPromotion(promotionId));
    } else {
      dispatch(resetState());
    }
  }, [promotionId]);

  useEffect(() => {
    if (isSuccess && createdPromotion) {
      toast.success("Promocija dodana uspješno!");
      setTimeout(() => {
        navigate("/admin/promotions");
      }, 100);
    }
    if (isSuccess && updatedPromotion) {
      toast.success(" Promocija ažurirana uspješno!");
      setTimeout(() => {
        navigate("/admin/promotions");
      }, 100);
    }
    if (isError) {
      toast.error(" Došlo je do greške!");
    }
  }, [isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: promotion?.title || "",
      description: promotion?.description || "",
      dateStart: promotion?.dateStart
        ? format(new Date(promotion.dateStart), "yyyy-MM-dd")
        : "",
      dateEnd: promotion?.dateEnd
        ? format(new Date(promotion.dateEnd), "yyyy-MM-dd")
        : "",
      images: promotion?.images || [],
      location: promotion?.location || [],
      content: promotion?.content || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const data = { id: promotionId, promotionData: values };
      if (promotionId !== undefined) {
        dispatch(updateAPromotion(data));
        formik.resetForm();
        setImages([]);
        setLocationVariable([]);
      } else {
        dispatch(createNewPromotion(values));
        formik.resetForm();
        setImages([]);
        setLocationVariable([]);
      }
    },
  });

  useEffect(() => {
    if (locationVariable.length) {
      formik.setFieldValue("location", locationVariable);
    }
  }, [locationVariable]);

  const handleLocation = (value) => {
    setLocationVariable(value);
  };

  const handleDelete = (image) => {
    const filename = image.split("/")[4];
    console.log(filename);
    for (let i = 0; i < images.length; i++) {
      if (images.length === 1) {
        setImages([]);
      } else if (images[i] === image) {
        setImages((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]);
      }
    }
  };

  const uploadImg = async (files) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    try {
      const res = await fetch(`${uploadServer}upload`, {
        method: "POST",
        body: data,
      });

      if (!res.ok) {
        throw new Error(`Server error: ${res.status}`);
      }

      const response = await res.json();

      // Assuming response.files contains an array of uploaded files with their URLs
      const uploadedImages = response.files.map((file) => file.url);

      setImages((prev) => [...prev, ...uploadedImages]);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  return (
    <div className="page">
      <h4 className="">
        {promotionId !== undefined ? "Uredi" : "Dodaj"} Promociju
      </h4>
      <p>
        {" "}
        Ovdje možete dodati novu promociju o vašem centru koja će se prikazivati
        na stranici.{" "}
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Naslov</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            onChange={formik.handleChange("title")}
            value={formik.values.title}
            onBlur={formik.handleBlur("title")}
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="description">Kratak opis</label>
          <ReactQuill
            theme="snow"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
            onBlur={() => formik.setFieldTouched("description", true)}
          />
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="content">Tekst</label>
          <ReactQuill
            theme="snow"
            id="content"
            name="content"
            value={formik.values.content}
            onChange={(value) => formik.setFieldValue("content", value)}
            onBlur={() => formik.setFieldTouched("content", true)}
          />
          <div className="error">
            {formik.touched.content && formik.errors.content}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="location">Odaberite centar </label>
          <Select
            id="location"
            name="location"
            mode="multiple"
            allowClear
            options={malls.map((mall) => ({
              value: mall.location,
              label: "Park &  Shop " + mall.location.toUpperCase(),
            }))}
            placeholder={
              promotion?.location?.length
                ? promotion.location.map(
                    (loc) => "Park & Shop " + loc.toUpperCase()
                  ) + " "
                : "Odaberite centar"
            }
            onChange={(value) => handleLocation(value)}
            style={{ width: "100%" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="dateStart">Datum početka</label>
          <input
            type="date"
            className="form-control"
            id="dateStart"
            name="dateStart"
            onChange={formik.handleChange("dateStart")}
            value={formik.values.dateStart}
            onBlur={formik.handleBlur("dateStart")}
          />
          <div className="error">
            {formik.touched.dateStart && formik.errors.dateStart}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="dateEnd">
            Datum završetka <small>( neobavezno )</small>
          </label>
          <input
            type="date"
            className="form-control"
            id="dateEnd"
            name="dateEnd"
            onChange={formik.handleChange("dateEnd")}
            value={formik.values.dateEnd}
            onBlur={formik.handleBlur("dateEnd")}
          />
          <div className="error">
            {formik.touched.dateEnd && formik.errors.dateEnd}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="images">Slike</label>
          <Dropzone
            onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p> Kliknite ovdje da bi dodali slike i videe </p>
                </div>
              </section>
            )}
          </Dropzone>
          <div className="error">
            {formik.touched.images && formik.errors.images}
          </div>
          <div className="images">
            {formik.values.images?.map((image, index) => (
              <div key={index} className="image">
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleDelete(image)}
                >
                  <BsTrash />
                </button>

                {image?.includes("mp4") ? (
                  <video src={image} controls />
                ) : (
                  <img src={image} alt="slika" />
                )}
              </div>
            ))}
          </div>
        </div>

        <button className="button" type="submit">
          {promotionId !== undefined ? "Uredi" : "Dodaj"} promociju
        </button>
      </form>
    </div>
  );
};

export default AddPromotion;
