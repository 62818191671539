import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllRequest = async () => {
  const response = await axios.get(`${base_url}lease-requests/`, config);

  return response.data;
};

const getRequest = async (id) => {
  const response = await axios.get(`${base_url}lease-requests/${id}`, config);

  return response.data;
};

const deleteARequest = async (id) => {
  const response = await axios.delete(
    `${base_url}lease-requests/${id}`,
    config
  );

  return response.data;
};

const sendRequestEmail = async (id) => {
  const response = await axios.post(
    `${base_url}lease-requests/send-lease-request-email/${id}`,
    config
  );

  return response.data;
};

const leaseRequestsService = {
  getAllRequest,
  getRequest,
  deleteARequest,
  sendRequestEmail,
};

export default leaseRequestsService;
