import React, { useEffect } from "react";
import "../styles/page.scss";
import {
  deleteANews,
  getNews,
  sendEmailNews,
  sendTestEmailNews,
  resetState,
} from "../features/news/newsSlice";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const News = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getNewsState = useSelector((state) => state.news.allNews);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    dispatch(resetState());
    dispatch(getNews());
  }, []);
  const deleteNews = (id) => {
    dispatch(deleteANews(id));
    setTimeout(() => {
      dispatch(getNews());
    }, 100);
  };

  return (
    <div className="page">
      <h4> Novosti </h4>
      <p> Ovdje mžete pogledati sve novosti koje su dodane u bazu podataka. </p>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>Naslov</th>
            <th>Datum početka</th>
            <th>Datum završetka</th>
            <th>Centar u kojem se nalazi</th>
            <th> Pošalji testnu email novosti samo sebi </th>
            <th> Pošalji novost korisnicima prijavljenim na newsletter </th>
            <th>Uredi novost</th>
            <th>Izbriši novost</th>
          </tr>
        </thead>
        <tbody>
          {getNewsState?.map((news, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{news.title}</td>
              <td>
                {" "}
                {news.dateStart
                  ? new Date(news.dateStart).toLocaleDateString("hr-HR")
                  : ""}
              </td>
              <td>
                {" "}
                {news.dateEnd
                  ? new Date(news.dateEnd).toLocaleDateString("hr-HR")
                  : ""}
              </td>
              <td>
                {news?.location?.map((mall, index) => (
                  <p key={index}> Park & Shop {" " + mall}</p>
                ))}
              </td>
              <td>
                {" "}
                <button
                  className="button"
                  onClick={() => dispatch(sendTestEmailNews(news._id))}
                >
                  {" "}
                  Pošalji{" "}
                </button>{" "}
              </td>

              <td>
                <button
                  className="button"
                  onClick={() => dispatch(sendEmailNews(news._id))}
                >
                  Pošalji
                </button>
              </td>
              <td>
                <Link to={`/admin/add-news/${news._id}`} className="button">
                  Uredi
                </Link>
              </td>
              <td>
                <button onClick={() => deleteNews(news._id)} className="button">
                  Izbriši
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default News;
