import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "../features/stores/storeSlice";
import newsReducer from "../features/news/newsSlice";
import userReducer from "../features/users/userSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import mallReducer from "../features/malls/mallSlice";
import uploadReducer from "../features/upload/uploadSlice";
import leaseRequestReducer from "../features/leaseRequests/leaseRequestsSlice";

export const store = configureStore({
  reducer: {
    store: storeReducer,
    news: newsReducer,
    user: userReducer,
    promotions: promotionsReducer,
    mall: mallReducer,
    upload: uploadReducer,
    leaseRequest: leaseRequestReducer,
  },
});

export default store;
