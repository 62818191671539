import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllStores = async () => {
  const response = await axios.get(`${base_url}stores/`, config);

  return response.data;
};
const createAStore = async (data) => {
  const response = await axios.post(`${base_url}stores/`, data, config);

  return response.data;
};
const updateStore = async (data) => {
  const response = await axios.put(
    `${base_url}stores/${data.id}`,
    data.storeData,
    config
  );

  return response.data;
};
const getStore = async (id) => {
  const response = await axios.get(`${base_url}stores/${id}`, config);
  return response.data;
};

const deleteStore = async (id) => {
  const response = await axios.delete(`${base_url}stores/${id}`, config);
  return response.data;
};
const storeService = {
  getAllStores,
  createAStore,
  updateStore,
  getStore,
  deleteStore,
};

export default storeService;
