import React, { useEffect } from "react";
import "../styles/page.scss";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteAPromotion,
  getPromotion,
  resetState,
  sendEmailPromotion,
  sendTestEmailPromotion,
} from "../features/promotions/promotionsSlice";
import { useNavigate } from "react-router-dom";

const Promotions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPromotionState = useSelector(
    (state) => state.promotions.allPromotions
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    dispatch(resetState());
    dispatch(getPromotion());
  }, []);
  const deletePromotion = (id) => {
    dispatch(deleteAPromotion(id));
    setTimeout(() => {
      dispatch(getPromotion());
    }, 100);
  };

  return (
    <div className="page">
      <h4> Promocije </h4>
      <p>
        {" "}
        Ovdje mžete pogledati sve promocije koje su dodane u bazu podataka.{" "}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>Naslov</th>
            <th>Datum početka</th>
            <th>Datum završetka</th>
            <th>Centar u kojem se nalazi</th>
            <th> Pošalji testnu email promocije samo sebi </th>
            <th> Pošalji promociju korisnicima prijavljenim na newsletter </th>
            <th>Uredi promociju</th>
            <th>Izbriši promociju</th>
          </tr>
        </thead>
        <tbody>
          {getPromotionState?.map((promotion, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{promotion?.title}</td>
              <td>
                {" "}
                {promotion?.dateStart
                  ? new Date(promotion.dateStart).toLocaleDateString("hr-HR")
                  : ""}
              </td>
              <td>
                {" "}
                {promotion?.dateEnd
                  ? new Date(promotion.dateEnd).toLocaleDateString("hr-HR")
                  : ""}
              </td>
              <td>
                {promotion?.location?.map((mall, index) => (
                  <p key={index}> Park & Shop {" " + mall}</p>
                ))}
              </td>
              <td>
                <button
                  className="button"
                  onClick={() => {
                    dispatch(sendTestEmailPromotion(promotion._id));
                  }}
                >
                  Pošalji
                </button>
              </td>
              <td>
                <button
                  className="button"
                  onClick={() => {
                    dispatch(sendEmailPromotion(promotion._id));
                  }}
                >
                  Pošalji
                </button>
              </td>
              <td>
                <Link
                  to={`/admin/add-promotion/${promotion._id}`}
                  className="button"
                >
                  Uredi
                </Link>
              </td>
              <td>
                <button
                  onClick={() => deletePromotion(promotion._id)}
                  className="button"
                >
                  izbriši
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Promotions;
