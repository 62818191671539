import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllNews = async () => {
  const response = await axios.get(`${base_url}news/`, config);

  return response.data;
};
const createANews = async (data) => {
  const response = await axios.post(`${base_url}news/`, data, config);

  return response.data;
};
const updateNews = async (data) => {
  const response = await axios.put(
    `${base_url}news/${data.id}`,
    data.newsData,
    config
  );

  return response.data;
};
const getNews = async (id) => {
  const response = await axios.get(`${base_url}news/${id}`, config);

  return response.data;
};

const deleteANews = async (id) => {
  const response = await axios.delete(`${base_url}news/${id}`, config);

  return response.data;
};

const sendNewsEmail = async (id) => {
  const response = await axios.post(
    `${base_url}news/send-news-email/${id}`,
    {},
    config
  );

  return response.data;
};

const sendTestNewsEmail = async (id) => {
  const response = await axios.post(
    `${base_url}news/send-test-news-email/${id}`,
    {},
    config
  );

  return response.data;
};

const newsService = {
  getAllNews,
  createANews,
  updateNews,
  getNews,
  deleteANews,
  sendNewsEmail,
  sendTestNewsEmail,
};

export default newsService;
