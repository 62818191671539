import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createNewStore,
  getAStore,
  resetState,
  updateAStore,
} from "../features/stores/storeSlice";
import { uploadImg, delImg } from "../features/upload/uploadSlice";
import "../styles/page.scss";
import Dropzone from "react-dropzone";
import { BsTrash } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getMall } from "../features/malls/mallSlice";
import { Select } from "antd";
import { getPromotion } from "../features/promotions/promotionsSlice";

let schema = yup.object().shape({
  title: yup.string().required("Niste upisali naziv trgovine"),
  phone: yup.string().required("Niste upisali broj telefona trgovine"),
  description: yup.string().required("Niste napisali opis trgovine"),
  images: yup.array().required("Morate dodati barem jednu sliku"),
  logo: yup.string().required("Niste dodali logo trgovine"),
});
const AddStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const storeId = location.pathname.split("/")[3];
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState(true);
  const [workingHours, setWorkingHours] = useState({
    ponedjeljak: "",
    utorak: "",
    srijeda: "",
    četvrtak: "",
    petak: "",
    subota: "",
    nedjelja: "",
  });
  const [locationVariable, setLocationVariable] = useState([]);
  const [promotionsVariable, setPromotionsVariable] = useState([]);

  const handleWorkingHoursChange = (day, value) => {
    setWorkingHours((prevWorkingHours) => ({
      ...prevWorkingHours,
      [day]: value,
    }));
  };

  const { isLoading, isError, isSuccess, createdStore, updatedStore, store } =
    useSelector((state) => state.store);
  let imagesState = useSelector((state) => state.upload.images.imageUrls);
  const malls = useSelector((state) => state.mall.allMalls);
  const promotions = useSelector((state) => state.promotions.allPromotions);

  const {
    isLoading: isLoadingUpload,
    isError: isErrorUpload,
    isSuccess: isSuccessUpload,
  } = useSelector((state) => state.upload);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    dispatch(getMall());
    dispatch(getPromotion());
  }, []);

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(getAStore(storeId));
    }
  }, [storeId]);

  useEffect(() => {
    if (store?.workingHours) {
      setWorkingHours(store?.workingHours);
    }
  }, [store]);

  useEffect(() => {
    if (storeId !== undefined) {
      if (store?.images) {
        setImages(store?.images);
      }
    }
  }, [store]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: store?.images || [],
      logo: store?.logo || "",
      locationImage: store?.locationImage || "",
      title: store?.title || "",
      phone: store?.phone || "",
      description: store?.description || "",
      websiteLink: store?.websiteLink || "",
      email: store?.email || "",
      promotions: store?.promotions || [],
      location: store?.location || "",
      workingHours: store?.workingHours || {},
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (storeId !== undefined) {
        dispatch(updateAStore({ id: storeId, storeData: values }));
        formik.resetForm();
        setWorkingHours({
          ponedjeljak: "",
          utorak: "",
          srijeda: "",
          četvrtak: "",
          petak: "",
          subota: "",
          nedjelja: "",
        });
        setImages([]);
      } else {
        dispatch(createNewStore(values));
        formik.resetForm();
        setWorkingHours({
          ponedjeljak: "",
          utorak: "",
          srijeda: "",
          četvrtak: "",
          petak: "",
          subota: "",
          nedjelja: "",
        });
        setImages([]);
      }
    },
  });

  const uploadToSpecificValue = (imgs, x) => {
    if (uploadImages) {
      alert("Molimo vas da opet dodate sliku koju želite dodati. ");
    } else {
      if (x === 0) {
        for (let i = 0; i < imgs.length; i++) {
          setImages((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, imgs[i]];
            } else {
              return [imgs[i]];
            }
          });
        }
      } else if (x === 1) {
        formik.setFieldValue("logo", imgs[0]);
      } else if (x === 2) {
        formik.setFieldValue("locationImage", imgs[0]);
      }

      setUploadImages(true);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      formik.setFieldValue("images", images);
    }
  }, [images]);

  useEffect(() => {
    formik.setFieldValue("workingHours", workingHours);
  }, [workingHours]);

  const handleDelete = (image, x) => {
    if (x === 0) {
      let filename = image.split("/")[4];
      dispatch(delImg(filename));

      if (images.length === 1) {
        setImages([]);
      } else {
        let newImages = images.filter((img) => img !== image);
        setImages(newImages);
      }
    } else if (x === 1) {
      formik.setFieldValue("logo", "");
      let filename = image.split("/")[4];
      dispatch(delImg(filename));
    } else if (x === 2) {
      formik.setFieldValue("locationImage", "");
      let filename = image.split("/")[4];
      dispatch(delImg(filename));
    }
  };

  useEffect(() => {
    if (promotionsVariable.length) {
      formik.setFieldValue("promotions", promotionsVariable);
    }
  }, [promotionsVariable]);

  const handlePromotions = (value) => {
    setPromotionsVariable(value);
  };

  useEffect(() => {
    if (locationVariable.length) {
      formik.setFieldValue("location", locationVariable);
    }
  }, [locationVariable]);

  const handleLocation = (value) => {
    setLocationVariable(value);
  };

  useEffect(() => {
    if (isSuccess && createdStore) {
      toast.success("Trgovina dodana uspješno!");
      navigate("/admin/store");
    }
    if (isSuccess && updatedStore) {
      toast.success(" Trgovina ažurirana uspješno!");
      navigate("/admin/store");
    }
    if (isError) {
      toast.error(" Došlo je do greške!");
    }
  }, [isSuccess, isError, isLoading]);

  const deleteAllPromotions = () => {
    formik.setFieldValue("promotions", []);
    setPromotionsVariable([]);
  };

  return (
    <div className="page">
      <h4 className="">{storeId !== undefined ? "Uredi" : "Dodaj"} Trgovinu</h4>
      <p>
        {" "}
        Ovdje možete dodati novu trgovinu o vašem centru koja će se prikazivati
        na stranici.{" "}
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="title"> Naziv trgovine </label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Upišite naziv trgovine"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title ? (
            <div className="error">{formik.errors.title}</div>
          ) : null}
        </div>

        <div className="form-group">
          <label htmlFor="location">Odaberite centar </label>
          <Select
            id="location"
            name="location"
            placeholder={
              store?.location?.length
                ? store.location.map(
                    (loc) => "Park & Shop " + loc.toUpperCase()
                  ) + " "
                : "Odaberite centar"
            }
            mode="multiple"
            allowClear
            options={malls.map((mall) => ({
              value: mall.location,
              label: "Park & Shop " + mall.location.toUpperCase(),
            }))}
            onChange={(value) => handleLocation(value)}
            style={{ width: "100%" }}
          />
        </div>

        <label htmlFor="images">Radno vrijeme trgovine</label>

        {Object.keys(workingHours).map((day) => (
          <div className="form-group" key={day} style={{ padding: "0 2rem" }}>
            <label htmlFor={day}>
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>
            <input
              type="text"
              className="form-control"
              id={day}
              placeholder={`Upisite rando vrijeme za ${day}`}
              value={workingHours[day]}
              onChange={(e) => handleWorkingHoursChange(day, e.target.value)}
            />
          </div>
        ))}

        <div className="form-group">
          <label htmlFor="description">Opis</label>
          <ReactQuill
            theme="snow"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
            onBlur={() => formik.setFieldTouched("description", true)}
          />
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="phone"> Broj telefona trgovine </label>
          <input
            type="text"
            className="form-control"
            id="phone"
            placeholder="Upišite broj telefona trgovine"
            onChange={formik.handleChange}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="error">{formik.errors.phone}</div>
          ) : null}
        </div>

        <div className="form-group">
          <label htmlFor="email"> Email trgovine </label>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="Upišite email trgovine"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="form-group">
          <label htmlFor="website"> Web stranica trgovine </label>
          <input
            type="text"
            className="form-control"
            id="websiteLink"
            placeholder="Upišite web stranicu trgovine"
            onChange={formik.handleChange}
            value={formik.values.websiteLink}
            onBlur={formik.handleBlur}
          />
          {formik.touched.websiteLink && formik.errors.websiteLink ? (
            <div className="error">{formik.errors.websiteLink}</div>
          ) : null}
          <div className="test-link">
            {formik.values.websiteLink != "" ? (
              <span>Klikni na link kako bi isprobao: </span>
            ) : null}
            <a
              href={
                formik.values.websiteLink.startsWith("http")
                  ? formik.values.websiteLink
                  : `http://${formik.values.websiteLink}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {formik.values.websiteLink}
            </a>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="images">
            {" "}
            Dodajte slike i videe, uploadajte ih te zatim odaberite logo,
            lokacijsku sliku ili galeriju slika{" "}
          </label>
          <Dropzone
            onDrop={(acceptedFiles) => {
              dispatch(uploadImg(acceptedFiles));
              setUploadImages(false);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p> Kliknite ovdje da bi dodali slike i videe </p>
                </div>
              </section>
            )}
          </Dropzone>
          <div className="buttons">
            <button
              className="btn"
              type="button"
              style={{ width: "100%" }}
              onClick={() => uploadToSpecificValue(imagesState, 0)}
              disabled={isLoadingUpload}
            >
              Dodaj u galeriju
            </button>
            <button
              className="btn"
              style={{ width: "100%" }}
              type="button"
              onClick={() => uploadToSpecificValue(imagesState, 1)}
              disabled={isLoadingUpload}
            >
              Dodaj logo
            </button>
            <button
              className="btn"
              style={{ width: "100%" }}
              type="button"
              onClick={() => uploadToSpecificValue(imagesState, 2)}
              disabled={isLoadingUpload}
            >
              Dodaj lokacijsku sliku
            </button>
          </div>
        </div>
        <div className="form-group">
          {images.length > 0 ? <p> Slike </p> : <p> Slike još nisu dodane</p>}
          <div className="images">
            {images.map((image, index) => (
              <div className="image" key={index}>
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleDelete(image, 0)}
                >
                  <BsTrash />
                </button>
                {image?.includes("mp4") ? (
                  <video src={image} controls />
                ) : (
                  <img src={image} alt="slika" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          {formik.values.logo ? <p> Logo </p> : <p> Logo još nije dodan </p>}
          <div className="images">
            <div className="image">
              {formik.values.logo ? (
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    handleDelete(formik.values.logo, 1);
                  }}
                >
                  <BsTrash />
                </button>
              ) : (
                ""
              )}

              {formik.values.logo ? (
                <img src={formik.values.logo} alt="slika" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          {formik.values.locationImage ? (
            <p> Lokacijska slika </p>
          ) : (
            <p> Lokacijska slika još nije dodana </p>
          )}
          <div className="images">
            <div className="image">
              {formik.values.locationImage ? (
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleDelete(formik.values.locationImage, 2)}
                >
                  <BsTrash />
                </button>
              ) : (
                ""
              )}

              {formik.values.locationImage ? (
                <img src={formik.values.locationImage} alt="slika" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <button
            className="button"
            type="button"
            onClick={() => deleteAllPromotions()}
            style={{ marginBottom: "10px" }}
          >
            Obriši sve promocije
          </button>
          <label htmlFor="news"> Dodajte promocije ovog dućana </label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            allowClear
            placeholder={
              store?.promotions.length > 0
                ? "Naslovi promocija koje ste dodali: " +
                  store?.promotions.map((promotion) => promotion.title)
                : "Kliknite ovdje kako bi dodali promocije"
            }
            onChange={(value) => handlePromotions(value)}
            options={promotions.map((promotion) => ({
              value: promotion._id,
              label: promotion.title,
            }))}
          />
        </div>

        <button className="button" type="submit">
          {storeId !== undefined ? "Uredi" : "Dodaj"} trgovinu
        </button>
      </form>
    </div>
  );
};

export default AddStore;
