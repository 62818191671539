import React, { useState, useEffect } from "react";
import "../styles/sidebar.scss";
import { Link, NavLink } from "react-router-dom";
import {
  AiOutlineFileAdd,
  AiOutlineHome,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import {
  BsBuildingAdd,
  BsBuildings,
  BsHouseDoor,
  BsNewspaper,
  BsCart,
  BsCartPlus,
  BsBag,
  BsBagPlus,
  BsQuestionCircle,
  BsGear,
} from "react-icons/bs";
import { TfiViewListAlt } from "react-icons/tfi";
import { FiLogOut } from "react-icons/fi";
import { BiLocationPlus } from "react-icons/bi";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const [mallActive, setMallActive] = useState(true);
  const navigate = useNavigate();
  const [newsActive, setNewsActive] = useState(true);
  const [promotionsActive, setPromotionsActive] = useState(true);
  const [storesActive, setStoresActive] = useState(true);
  const [leaseRequestsActive, setLeaseRequestsActive] = useState(true);

  const { sidebar, setSidebar } = React.useContext(Context);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <div className={sidebar ? "sidebar active" : "sidebar"}>
      <div className="sidebar-container">
        <div className="sidebar-container__logo">
          <img
            src="https://parkandshop.hr/images/logo_parkandshop.png"
            alt="logo"
          />
        </div>

        <div className="sidebar-container-menu">
          <div className="sidebar-container-menu-item">
            <NavLink to="/admin" className="sidebar-container-menu-item-link">
              <BsHouseDoor className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Početna stranica
              </span>
            </NavLink>
          </div>

          <div className="sidebar-container-menu-item">
            <button
              onClick={() => setMallActive(!mallActive)}
              className={
                mallActive
                  ? "sidebar-container-menu-item-link active"
                  : "sidebar-container-menu-item-link"
              }
            >
              <BsBuildings className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Centri
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className={
                  mallActive
                    ? "sidebar-container-menu-item-link-arrow active"
                    : "sidebar-container-menu-item-link-arrow"
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              className={
                mallActive
                  ? "sidebar-container-menu-item-submenu active"
                  : "sidebar-container-menu-item-submenu"
              }
            >
              <NavLink
                to="/admin/mall"
                className="sidebar-container-menu-item-link"
              >
                <BsBuildings className="sidebar-container-menu-item-link-icon" />

                <span className="sidebar-container-menu-item-link-text">
                  Svi centri
                </span>
              </NavLink>
              <NavLink
                to="/admin/add-mall"
                className="sidebar-container-menu-item-link"
              >
                <BsBuildingAdd className="sidebar-container-menu-item-link-icon" />
                <span className="sidebar-container-menu-item-link-text">
                  Dodaj novi centar
                </span>
              </NavLink>
            </div>
          </div>

          <div className="sidebar-container-menu-item">
            <button
              onClick={() => setNewsActive(!newsActive)}
              className={
                newsActive
                  ? "sidebar-container-menu-item-link active"
                  : "sidebar-container-menu-item-link"
              }
            >
              <BsNewspaper className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Novosti
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className={
                  newsActive
                    ? "sidebar-container-menu-item-link-arrow active"
                    : "sidebar-container-menu-item-link-arrow"
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              className={
                newsActive
                  ? "sidebar-container-menu-item-submenu active"
                  : "sidebar-container-menu-item-submenu"
              }
            >
              <NavLink
                to="/admin/news"
                className="sidebar-container-menu-item-link"
              >
                <BsNewspaper className="sidebar-container-menu-item-link-icon" />

                <span className="sidebar-container-menu-item-link-text">
                  Sve novosti
                </span>
              </NavLink>
              <NavLink
                to="/admin/add-news"
                className="sidebar-container-menu-item-link"
              >
                <AiOutlineFileAdd className="sidebar-container-menu-item-link-icon" />
                <span className="sidebar-container-menu-item-link-text">
                  Dodaj novost
                </span>
              </NavLink>
            </div>
          </div>
          <div className="sidebar-container-menu-item">
            <button
              onClick={() => setPromotionsActive(!promotionsActive)}
              className={
                promotionsActive
                  ? "sidebar-container-menu-item-link active"
                  : "sidebar-container-menu-item-link"
              }
            >
              <TfiViewListAlt className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Promocije
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className={
                  promotionsActive
                    ? "sidebar-container-menu-item-link-arrow active"
                    : "sidebar-container-menu-item-link-arrow"
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              className={
                promotionsActive
                  ? "sidebar-container-menu-item-submenu active"
                  : "sidebar-container-menu-item-submenu"
              }
            >
              <NavLink
                to="/admin/promotions"
                className="sidebar-container-menu-item-link"
              >
                <TfiViewListAlt className="sidebar-container-menu-item-link-icon" />

                <span className="sidebar-container-menu-item-link-text">
                  Sve promocije
                </span>
              </NavLink>
              <NavLink
                to="/admin/add-promotion"
                className="sidebar-container-menu-item-link"
              >
                <AiOutlineFileAdd className="sidebar-container-menu-item-link-icon" />
                <span className="sidebar-container-menu-item-link-text">
                  Dodaj promociju
                </span>
              </NavLink>
            </div>
          </div>
          <div className="sidebar-container-menu-item">
            <button
              onClick={() => setStoresActive(!storesActive)}
              className={
                storesActive
                  ? "sidebar-container-menu-item-link active"
                  : "sidebar-container-menu-item-link"
              }
            >
              <BsBag className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Trgovine
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className={
                  storesActive
                    ? "sidebar-container-menu-item-link-arrow active"
                    : "sidebar-container-menu-item-link-arrow"
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              className={
                storesActive
                  ? "sidebar-container-menu-item-submenu active"
                  : "sidebar-container-menu-item-submenu"
              }
            >
              <NavLink
                to="/admin/store"
                className="sidebar-container-menu-item-link"
              >
                <BsBag className="sidebar-container-menu-item-link-icon" />

                <span className="sidebar-container-menu-item-link-text">
                  Sve trgovine
                </span>
              </NavLink>
              <NavLink
                to="/admin/add-store"
                className="sidebar-container-menu-item-link"
              >
                <BsBagPlus className="sidebar-container-menu-item-link-icon" />
                <span className="sidebar-container-menu-item-link-text">
                  Dodaj trgovinu
                </span>
              </NavLink>
            </div>
          </div>

          <div className="sidebar-container-menu-item">
            <button
              onClick={() => setLeaseRequestsActive(!leaseRequestsActive)}
              className={
                leaseRequestsActive
                  ? "sidebar-container-menu-item-link active"
                  : "sidebar-container-menu-item-link"
              }
            >
              <BiLocationPlus className="sidebar-container-menu-item-link-icon" />
              <span className="sidebar-container-menu-item-link-text">
                Zakup prostora
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className={
                  leaseRequestsActive
                    ? "sidebar-container-menu-item-link-arrow active"
                    : "sidebar-container-menu-item-link-arrow"
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              className={
                leaseRequestsActive
                  ? "sidebar-container-menu-item-submenu active"
                  : "sidebar-container-menu-item-submenu"
              }
            >
              <NavLink
                to="/admin/lease-requests"
                className="sidebar-container-menu-item-link"
              >
                <BiLocationPlus className="sidebar-container-menu-item-link-icon" />

                <span className="sidebar-container-menu-item-link-text">
                  Svi zahtjevi
                </span>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="sidebar-container-footer">
          <button className="logut" onClick={() => handleLogout()}>
            <FiLogOut className="logout-icon" />
            <span className="logout-text">Odjava</span>
          </button>

          <p>© 2023. MSK. Sva prava pridržana.</p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
