import React, { useEffect } from "react";
import "../styles/page.scss";
import { getMall, deleteAMall, resetState } from "../features/malls/mallSlice";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
const { useDispatch, useSelector } = require("react-redux");
const { toast } = require("react-toastify");
const { useNavigate } = require("react-router-dom");

const Mall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getMallState = useSelector((state) => state.mall.allMalls);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    dispatch(resetState());
    dispatch(getMall());
  }, []);
  const deleteMall = (location) => {
    dispatch(deleteAMall(location));
    setTimeout(() => {
      dispatch(getMall());
    }, 100);
    toast.success("Centar je uspješno izbrisan!");
  };

  return (
    <div className="page">
      <h4> Centri</h4>
      <p> Ovdje možete pogledati sve centre koji se nalaaze u bazi podataka.</p>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>Ime</th>
            <th> Uredi </th>
            <th> Izbriši </th>
          </tr>
        </thead>
        <tbody>
          {getMallState?.map((mall, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>Park & Shop {" " + mall.location.toUpperCase()}</td>
              <td>
                <Link
                  to={`/admin/add-mall/${mall.location}`}
                  className="button"
                >
                  uredi
                </Link>
              </td>
              <td>
                <button
                  onClick={() => deleteMall(mall.location)}
                  className="button"
                >
                  izbriši
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Mall;
