import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllPromotion = async () => {
  const response = await axios.get(`${base_url}promotion/`, config);

  return response.data;
};
const createAPromotion = async (data) => {
  const response = await axios.post(`${base_url}promotion/`, data, config);

  return response.data;
};
const updatePromotion = async (data) => {
  const response = await axios.put(
    `${base_url}promotion/${data.id}`,
    data.promotionData,
    config
  );

  return response.data;
};
const getPromotion = async (id) => {
  const response = await axios.get(`${base_url}promotion/${id}`, config);

  return response.data;
};

const deleteAPromotion = async (id) => {
  const response = await axios.delete(`${base_url}promotion/${id}`, config);

  return response.data;
};

const sendPromotionEmail = async (id) => {
  const response = await axios.post(
    `${base_url}promotion/send-promotion-email/${id}`,
    {},
    config
  );

  return response.data;
};

const sendTestPromotionEmail = async (id) => {
  const response = await axios.post(
    `${base_url}promotion/send-test-promotion-email/${id}`,
    {},
    config
  );

  return response.data;
};

const promotionService = {
  getAllPromotion,
  createAPromotion,
  updatePromotion,
  getPromotion,
  deleteAPromotion,
  sendPromotionEmail,
  sendTestPromotionEmail,
};

export default promotionService;
