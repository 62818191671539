import React, { useEffect } from "react";
import "../styles/page.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const malls = useSelector((state) => state.mall.allMalls);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
  }, []);

  return (
    <div className="page">
      <h3> Dobrodošli na admin aplikaciju </h3>
      <p>
        Ovdje možete dodavati, mijenjati i brisati podatke iz baze podataka.
      </p>
    </div>
  );
};

export default Home;
