import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import leaseRequestsService from "./leaseRequestsService";

export const getRequest = createAsyncThunk(
  "lease-requests/get-lease-requests",
  async (thunkAPI) => {
    try {
      return await leaseRequestsService.getAllRequest();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getARequest = createAsyncThunk(
  "lease-requests/get-lease-request",
  async (id, thunkAPI) => {
    try {
      return await leaseRequestsService.getRequest(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteARequest = createAsyncThunk(
  "lease-requests/delete-lease-request",
  async (id, thunkAPI) => {
    try {
      return await leaseRequestsService.deleteARequest(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmailRequest = createAsyncThunk(
  "lease-requests/send-email-promotion",
  async (id, thunkAPI) => {
    try {
      return await leaseRequestsService.sendRequestEmail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  allRequests: [],
  request: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

const leaseRequestsSlice = createSlice({
  name: "lease-requests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.allRequests = payload;
      })
      .addCase(getRequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = payload;
      })
      .addCase(getARequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getARequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.request = payload;
      })
      .addCase(getARequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = payload;
      })
      .addCase(deleteARequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteARequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = payload;
      })
      .addCase(deleteARequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = payload;
      })
      .addCase(sendEmailRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmailRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = payload;
      })
      .addCase(sendEmailRequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = payload;
      })
      .addCase(resetState, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      });
  },
});

export default leaseRequestsSlice.reducer;
