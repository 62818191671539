import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import promotionService from "./promotionsService";
import { toast } from "react-toastify";

export const getPromotion = createAsyncThunk(
  "promotion/get-promotion",
  async (thunkAPI) => {
    try {
      return await promotionService.getAllPromotion();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createNewPromotion = createAsyncThunk(
  "promotion/create-promotion",
  async (data, thunkAPI) => {
    try {
      return await promotionService.createAPromotion(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAPromotion = createAsyncThunk(
  "promotion/get-a-promotion",
  async (id, thunkAPI) => {
    try {
      return await promotionService.getPromotion(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAPromotion = createAsyncThunk(
  "promotion/update-promotion",
  async (data, thunkAPI) => {
    try {
      return await promotionService.updatePromotion(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAPromotion = createAsyncThunk(
  "promotion/delete-promotion",
  async (id, thunkAPI) => {
    try {
      return await promotionService.deleteAPromotion(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmailPromotion = createAsyncThunk(
  "promotion/send-email-promotion",
  async (id, thunkAPI) => {
    try {
      return await promotionService.sendPromotionEmail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendTestEmailPromotion = createAsyncThunk(
  "promotion/send-test-promotion-email",
  async (id, thunkAPI) => {
    try {
      return await promotionService.sendTestPromotionEmail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  allPromotions: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.allPromotions = action.payload;
      })
      .addCase(getPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createNewPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdPromotion = action.payload;
      })
      .addCase(createNewPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.promotion = action.payload;
      })
      .addCase(getAPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedPromotion = action.payload;
      })
      .addCase(updateAPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedPromotion = action.payload;
      })
      .addCase(deleteAPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(sendEmailPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmailPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success(" E-mail uspješno poslan");
      })
      .addCase(sendEmailPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        toast.error(" E-mail nije uspješno poslan");
      })
      .addCase(sendTestEmailPromotion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendTestEmailPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success(" E-mail uspješno poslan samo vama");
      })
      .addCase(sendTestEmailPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        toast.error(" E-mail nije uspješno poslan vama");
      })

      .addCase(resetState, () => initialState);
  },
});

export default promotionSlice.reducer;
