import React, { useEffect } from "react";
import "../styles/page.scss";
import {
  deleteARequest,
  getRequest,
} from "../features/leaseRequests/leaseRequestsSlice";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LeaseRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getRequestsState = useSelector(
    (state) => state.leaseRequest.allRequests
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    setTimeout(() => {
      dispatch(getRequest());
    }, 300);
  }, []);
  const deleteRequest = (id) => {
    dispatch(deleteARequest(id));
    setTimeout(() => {
      dispatch(getRequest());
    }, 100);
    toast.success("Zahtjev za najam je uspješno izbrisan!");
  };

  return (
    <div className="page">
      <h4> Zahtjevi za najam </h4>
      <p>
        {" "}
        Ovdje možete pogledati sve zahtjeve za najam koji su dodani u bazu
        podataka.{" "}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>Ime</th>
            <th>Prezime</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>Firma </th>
            <th> Centar u kojem se nalazi </th>
            <th> Vrsta prostora </th>
            <th> Izbriši zahtjev </th>
          </tr>
        </thead>
        <tbody>
          {getRequestsState?.map((request, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{request.firstname}</td>
              <td>{request.lastname}</td>
              <td>{request.email}</td>
              <td>{request.phone}</td>
              <td>{request.company}</td>
              <td>
                {request.location?.map((location, index) => (
                  <p key={index}> Park & Shop {" " + location} </p>
                ))}
              </td>
              <td>{request.leaseType}</td>
              <td>
                <button onClick={() => deleteRequest(request._id)}>
                  <AiFillDelete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaseRequests;
