import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Login from "./pages/Login";
import AddNews from "./pages/AddNews";
import Home from "./pages/Home";
import News from "./pages/News";
import Promotions from "./pages/Promotions";
import AddMall from "./pages/AddMall";
import Mall from "./pages/Mall";
import AddStore from "./pages/AddStore";
import Store from "./pages/Stores";
import AddPromotion from "./pages/AddPromotion";
import Layout from "./components/Layout";
import LeaseRequests from "./pages/LeaseRequests";

export const Context = React.createContext();

function App() {
  const [sidebar, setSidebar] = useState(false);

  return (
    <>
      <Context.Provider value={{ sidebar, setSidebar }}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<h1> Not Found </h1>} />
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<h1> Reset Password </h1>} />
            <Route
              path="/forgot-password"
              element={<h1 className="text-center">Forgot Password</h1>}
            />
            <Route path="/admin" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="news" element={<News />} />
              <Route path="add-news/:id" element={<AddNews />} />
              <Route path="add-news/" element={<AddNews />} />
              <Route path="promotions" element={<Promotions />} />
              <Route path="add-promotion/:id" element={<AddPromotion />} />
              <Route path="add-promotion/" element={<AddPromotion />} />
              <Route path="add-mall/:id" element={<AddMall />} />
              <Route path="add-mall/" element={<AddMall />} />
              <Route path="mall" element={<Mall />} />
              <Route path="add-store/:id" element={<AddStore />} />
              <Route path="add-store/" element={<AddStore />} />
              <Route path="store" element={<Store />} />
              <Route path="lease-requests" element={<LeaseRequests />} />
              <Route path="*" element={<h1> Not Found </h1>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </>
  );
}

export default App;
