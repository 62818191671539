import React, { useEffect, useState } from "react";
import "../styles/page.scss";
import {
  createNewMall,
  getAMall,
  resetState,
  updateAMall,
} from "../features/malls/mallSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { uploadImg, delImg } from "../features/upload/uploadSlice";
import "../styles/page.scss";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";
import { BsTrash } from "react-icons/bs";
import "react-quill/dist/quill.snow.css";

let schema = yup.object().shape({
  location: yup.string().required("Unesite lokaciju centra"),
  description: yup.string().required("Unesite opis centra"),
  address: yup.string().required("Unesite adresu centra"),
  logo: yup.string().required("Unesite logo centra"),
});

const AddMall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mallLocation = location.pathname.split("/")[3];
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState(true);

  let imagesState = useSelector((state) => state.upload.images.imageUrls);

  const { mall, isError, isSuccess, isLoading, createdMall, updatedMall } =
    useSelector((state) => state.mall);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch the token from local storage
    const user = localStorage.getItem("user");

    if (user === null) {
      alert("Niste prijavljeni!");
      navigate("/");
    }
    if (mallLocation) {
      dispatch(getAMall(mallLocation));
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: mall?.location || "",
      description: mall?.description || "",
      address: mall?.address || "",
      logo: mall?.logo || "",
      images: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (mallLocation !== undefined) {
        dispatch(updateAMall({ location: mallLocation, mallData: values }));
        setImages([]);
      } else {
        dispatch(createNewMall(values));
        setImages([]);
      }
    },
  });

  const uploadToSpecificValue = (imgs, x) => {
    if (uploadImages) {
      alert("Molimo vas da opet dodate sliku koju želite dodati. ");
    } else {
      if (x === 0) {
        for (let i = 0; i < imgs?.length; i++) {
          setImages((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, imgs[i]];
            } else {
              return [imgs[i]];
            }
          });
        }
      } else if (x === 1) {
        formik.setFieldValue("logo", imgs[0]);
      }
      setUploadImages(true);
    }
  };

  const handleDelete = (image, x) => {
    if (x === 0) {
      let filename = image.split("/")[4];
      dispatch(delImg(filename));

      if (images?.length === 1) {
        setImages([]);
      } else {
        let newImages = images?.filter((img) => img !== image);
        setImages(newImages);
      }
    } else if (x === 1) {
      formik.setFieldValue("logo", "");
      let filename = image.split("/")[4];
      dispatch(delImg(filename));
    }
  };

  useEffect(() => {
    if (isSuccess && createdMall) {
      toast.success("Trgovina dodana uspješno!");
      navigate("/admin/mall");
    }
    if (isSuccess && updatedMall) {
      toast.success(" Trgovina ažurirana uspješno!");
      navigate("/admin/mall");
    }
    if (isError) {
      toast.error(" Došlo je do greške!");
    }
  }, [isSuccess, isError, isLoading]);

  useEffect(() => {
    if (images?.length > 0) {
      formik.setFieldValue("images", images);
    }
  }, [images]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setImages(mall?.images);
      }, 300);
    }
  }, [mall]);

  return (
    <div className="page">
      <h4 className="page-title">Dodajte trgovački centar</h4>
      <p className="page-description">
        Ovdje možete dodati novi trgovački centar. Prikazivanje na stranici će
        biti omogućeno kasnije putem dogovora.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="location">Lokacija</label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            placeholder="Unesite lokaciju centra"
            onChange={formik.handleChange}
            value={formik.values.location}
          />
          {formik.touched.location && formik.errors.location ? (
            <div className="error">{formik.errors.title}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="description">Opis</label>
          <ReactQuill
            theme="snow"
            id="description"
            name="description"
            placeholder="Unesite opis centra"
            onChange={(e) => formik.setFieldValue("description", e)}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="error">{formik.errors.description}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="address">Adresa</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            placeholder="Unesite adresu centra"
            onChange={formik.handleChange}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="error">{formik.errors.address}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="images">
            {" "}
            Dodajte slike i videe, uploadajte ih te zatim odaberite logo,
            lokacijsku sliku ili galeriju slika{" "}
          </label>
          <Dropzone
            onDrop={(acceptedFiles) => {
              dispatch(uploadImg(acceptedFiles));
              setUploadImages(false);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p> Kliknite ovdje da bi dodali slike i videe </p>
                </div>
              </section>
            )}
          </Dropzone>
          <div className="buttons">
            <button
              className="btn"
              type="button"
              onClick={() => uploadToSpecificValue(imagesState, 0)}
            >
              Dodaj u galeriju
            </button>
            <button
              className="btn"
              type="button"
              onClick={() => uploadToSpecificValue(imagesState, 1)}
            >
              Dodaj logo
            </button>
          </div>
        </div>
        <div className="form-group">
          {images?.length > 0 ? <p> Slike </p> : <p> Slike još nisu dodane</p>}
          <div className="images">
            {images?.map((image, index) => (
              <div className="image" key={index}>
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleDelete(image, 0)}
                >
                  <BsTrash />
                </button>
                {image?.includes("mp4") ? (
                  <video src={image} controls />
                ) : (
                  <img src={image} alt="slika" />
                )}
              </div>
            ))}
          </div>
          {formik.touched.images && formik.errors.images ? (
            <div className="error">{formik.errors.images}</div>
          ) : null}
        </div>
        <div className="form-group">
          {formik.values.logo ? <p> Logo </p> : <p> Logo još nije dodan </p>}
          <div className="images">
            <div className="image">
              {formik.values.logo ? (
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleDelete(formik.values.logo, 1)}
                >
                  <BsTrash />
                </button>
              ) : (
                ""
              )}

              {formik.values.logo ? (
                <img src={formik.values.logo} alt="slika" />
              ) : (
                ""
              )}
            </div>
          </div>
          {formik.touched.logo && formik.errors.logo ? (
            <div className="error">{formik.errors.logo}</div>
          ) : null}
        </div>

        <button className="button" type="submit">
          {mallLocation !== undefined ? "Uredi" : "Dodaj"}
        </button>
      </form>
    </div>
  );
};

export default AddMall;
