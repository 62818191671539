import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import newsService from "./newsService";
import { toast } from "react-toastify";

export const getNews = createAsyncThunk("news/get-news", async (thunkAPI) => {
  try {
    return await newsService.getAllNews();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const createNewNews = createAsyncThunk(
  "news/create-news",
  async (data, thunkAPI) => {
    try {
      return await newsService.createANews(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getANews = createAsyncThunk(
  "news/get-a-news",
  async (id, thunkAPI) => {
    try {
      return await newsService.getNews(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateANews = createAsyncThunk(
  "news/update-news",
  async (data, thunkAPI) => {
    try {
      return await newsService.updateNews(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteANews = createAsyncThunk(
  "news/delete-news",
  async (id, thunkAPI) => {
    try {
      return await newsService.deleteANews(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmailNews = createAsyncThunk(
  "news/send-news-email",
  async (id, thunkAPI) => {
    try {
      return await newsService.sendNewsEmail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendTestEmailNews = createAsyncThunk(
  "news/send-test-email-news",
  async (id, thunkAPI) => {
    try {
      return await newsService.sendTestNewsEmail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  allNews: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.allNews = action.payload;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createNewNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdNews = action.payload;
      })
      .addCase(createNewNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getANews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getANews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.news = action.payload;
      })
      .addCase(getANews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateANews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateANews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedNews = action.payload;
      })
      .addCase(updateANews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteANews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteANews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedNews = action.payload;
      })
      .addCase(deleteANews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(sendEmailNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmailNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.emailNews = action.payload;
        toast.success("Uspješno poslan email!");
      })
      .addCase(sendEmailNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        toast.error("Neuspješno poslan email!");
      })
      .addCase(sendTestEmailNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendTestEmailNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.testEmailNews = action.payload;
        toast.success("Uspješno poslan testni email!");
      })
      .addCase(sendTestEmailNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        toast.error("Neuspješno poslan testni email!");
      })

      .addCase(resetState, () => initialState);
  },
});

export default newsSlice.reducer;
